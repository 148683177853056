<template>
  <div @click="ganbi">
    <div class="content">
      <div class="contentBox">
        <div class="shopHeadBox">
          <div class="img">
            <img src="../../assets/img/logo-p.png" alt="" />
          </div>
          <div class="inpBox">
            <input type="text" placeholder="请输入关键词搜索" 
                  v-model="seachName"
                  @keyup.enter="searchClick()"/>
            <div class="searchBtn" @click="searchClick">搜索</div>
          </div>
        </div>
        <div class="contBox">
          <div class="leftCont">
            <div class="leftTitle">全部产品({{ totalCount }})</div>
            <div class="funBox">
              <div class="box" @click="allClick">
                <div class="kuang" :class="allSelect ? 'on' : ''">
                  <div class="iconfont icon-right" v-if="allSelect"></div>
                </div>
                <div class="text">全选</div>
              </div>
              <div class="txt" @click="delClick">删除</div>
            </div>
            <div class="contList">
              <Kong v-if="list.length == 0" />
              <div class="cont" v-for="(item, index) in list">
                <div class="contHead" @click="slectClick(index)">
                  <div class="kuang" :class="item.slect ? 'on' : ''">
                    <div class="iconfont icon-right" v-if="item.slect"></div>
                  </div>
                  <div class="time">{{ item.productname }}</div>
                </div>
                <div class="contBox">
                  <div class="cpBox w20">
                    <div class="img" @click="cpJump(item.part.id)">
                      <div class="delBox" v-if="item.part.is_arrive">已下架</div>
                      <img
                        v-if="item.part.resources"
                        :src="$imgUrl + item.part.resources[0].path_name"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="text w30">
                    <div class="ggBox" :class="item.xz ? 'on' : ''">
                      <div
                        class="box"
                        :class="item.xz ? 'onShow' : ''"
                        @click.stop="ggClick(index)"
                      >
                        修改
                      </div>
                      规格：{{ item.spec.specss ? item.spec.specss : "无" }}
                    </div>
                    <!-- <div class="ggCont" v-if="item.xz" @click.stop="stop()">
                      <div class="boxCont scroll">
                        <div class="boxs">
                          <div class="name">规格</div>
                          <div class="box">
                            <div class="txt">配置</div>
                            <div class="txt">配置</div>
                            <div class="txt">配置</div>
                            <div class="txt">配置</div>
                            <div class="txt">配置</div>
                          </div>
                        </div>
                        <div class="boxs">
                          <div class="name">规格</div>
                          <div class="box">
                            <div class="txt">配置</div>
                            <div class="txt">配置</div>
                            <div class="txt">配置</div>
                            <div class="txt">配置</div>
                            <div class="txt">配置</div>
                          </div>
                        </div>
                      </div>
                      <div class="ggBtns">
                        <div class="ggBtn dis-cent">确定</div>
                        <div
                          class="ggBtn1 dis-cent"
                          @click.stop="ggHideClick(index)"
                        >
                          取消
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="text w10">
                    <div class="name">￥{{ item.price }}</div>
                    <!-- <div class="txt">(含运费:¥0.00)</div> -->
                    <div class="txt">(包邮)</div>
                  </div>
                  <div class="numBox w20">
                    <div class="box">
                      <div
                        class="iconfont icon-jian1 jian dis-cent"
                        @click="jianClick(item, index)"
                      ></div>
                      <div class="num">
                        <input
                          type="text"
                          v-model="item.num"
                          @blur="handleBlur(item, index)"
                        />
                      </div>
                      <div
                        class="iconfont icon-add jia dis-cent"
                        @click="addClick(item, index)"
                      ></div>
                    </div>
                  </div>
                  <div class="text w20">
                    <a-popconfirm
                      title="是否确定删除"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="confirm(item, index)"
                    >
                      <div class="butBox dis-cent">删除</div>
                    </a-popconfirm>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightCont">
            <div class="cont">
              <div class="contHead">
                <div class="name">结算明细</div>
                <div class="txt">
                  已选<span>{{ xzList.length }}</span
                  >件产品
                </div>
              </div>
              <div class="box dis-cent" v-if="xzList.length == 0">
                还没有待结算的产品
              </div>
              <div class="cpBox" v-if="xzList.length != 0">
                <div class="imgs">
                  <div class="img" v-for="item in xzList">
                    <img
                      v-if="item.part.resources"
                      :src="$imgUrl + item.part.resources[0].path_name"
                      alt=""
                    />
                    <div class="text">{{ item.productname }}</div>
                  </div>
                </div>
                <div class="contTxt">
                  <div class="name">商品总价</div>
                  <div class="txt"><span>￥</span>{{ price }}</div>
                </div>
              </div>
              <div class="contBtn">
                <div class="moneyBox">
                  <div class="name">合计:</div>
                  <div class="num">{{ price }}</div>
                </div>
                <div class="saveBox" @click="saveClick">结算</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model="visible" title="提示" @ok="handleOk">
      <p>是否确定确定删除所有选中</p>
    </a-modal>
  </div>
</template>

<script>
import { Popconfirm, Modal } from "ant-design-vue";
import Kong from "../component/kong.vue";
import Vue from "vue";
Vue.use(Modal);
export default {
  components: {
    Kong,
    "a-popconfirm": Popconfirm,
    "a-modal": Modal,
  },
  name: "",
  data() {
    return {
      pd: 0,
      visible: false,
      allSelect: false,
      // list: [
      //   {
      //     slect: false,
      //     xz: false,
      //     num: 1,
      //   },
      //   {
      //     slect: false,
      //     xz: false,
      //     num: 1,
      //   },
      //   {
      //     slect: false,
      //     num: 1,
      //     xz: false,
      //   },
      //   {
      //     slect: false,
      //     xz: false,
      //     num: 1,
      //   },
      // ],
      judge: true,
      page: {
        pageNo: 1,
        pageSize: 20,
      },
      list: [],
      xzList: [],
      totalCount: 0,
      price: "0.00",
      ids: "",
      seachName: "",
    };
  },
  created() {
    this.listGet();
  },
  methods: {
    confirm(item, i) {
      this.delGet(item.id, i);
    },
    handleOk() {
      this.$post("/web/car_delete", {
        id: this.ids,
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          this.visible = false;
          this.$message.success("删除成功");
          this.page.pageNo = 1;
          this.list = [];
          this.xzList = [];
          this.listGet();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    delClick() {
      let ids = [];
      for (let i = 0; i < this.xzList.length; i++) {
        const e = this.xzList[i];
        ids.push(e.id);
      }
      this.ids = ids.join(",");
      if (!this.ids) {
        this.$message.warning("请至少选中一件产品");
        return;
      }
      this.visible = true;
      console.log(this.ids);
    },
    delGet(id, i) {
      this.$post("/web/car_delete", {
        id,
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          this.$message.success("删除成功");
          this.list.splice(i, 1);
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    listGet() {
      if (!this.judge) {
        return;
      }
      this.judge = false;
      this.$get("/web/car_list", this.page).then((res) => {
        console.log(res);
        this.judge = true;
        if (!this.totalCount) {
          this.totalCount = res.totalCount;
        }
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            if (e.spec.id) {
              e.spec.spec = JSON.parse(e.spec.spec);
              e.spec.specss = [];
              for (const j in e.spec.spec) {
                if (Object.hasOwnProperty.call(e.spec.spec, j)) {
                  const ej = e.spec.spec[j];
                  if (j != "name") {
                    e.spec.specss.push(ej);
                  }
                }
              }
              e.spec.specss = e.spec.specss.join(",");
            }
            e.slect = false;
            this.list.push(e);
          }
          this.page.pageNo++;
        } else {
          if (this.page.pageNo != 1) {
            this.$message.warning("已展示全部内容");
          }
        }
      });
    },
    cpJump(id) {
      this.$router.push({
        path: "/shopCont",
        query: {
          id
        },
      });
    },
    stop() {},
    allClick() {
      if (this.allSelect) {
        this.allSelect = false;
        for (let i = 0; i < this.list.length; i++) {
          const e = this.list[i];
          e.slect = false;
        }
      } else {
        this.allSelect = true;
        for (let i = 0; i < this.list.length; i++) {
          const e = this.list[i];
          e.slect = true;
        }
      }
      this.pushList();
    },
    slectClick(i) {
      this.list[i].slect = !this.list[i].slect;
      let pd = 0;
      for (let i = 0; i < this.list.length; i++) {
        const e = this.list[i];
        if (!e.slect) {
          pd = 1;
        }
      }
      if (pd == 0) {
        this.allSelect = true;
      } else {
        this.allSelect = false;
      }
      this.pushList();
    },
    pushList() {
      let xzList = [];
      let price = 0;
      for (let i = 0; i < this.list.length; i++) {
        const e = this.list[i];
        if (e.slect) {
          price = price + e.price * e.num;
          xzList.push(e);
        }
      }
      this.xzList = xzList;
      this.price = price;
    },
    saveClick() {
      let ids = [];
      for (let i = 0; i < this.xzList.length; i++) {
        const e = this.xzList[i];
        ids.push(e.id);
      }
      ids = ids.join(",");
      console.log(ids);
      this.$post("/web/order_add", {
        id: ids,
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message.success(res.data.msg);
          this.$router.push({
            path: "/orderCont",
            query: {
              id: res.data.data,
            },
          });
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    searchClick() {
      this.page.name = this.seachName;
      this.page.pageNo = 1;
      this.list = [];
      this.listGet();
    },
    jianClick(item, i) {
      if (this.list[i].num > 1) {
        this.list[i].num--;
        this.numGet(item.id, item.num, i);
        this.pushList();
      } else {
        this.$message.error("产品数量不能小1件");
      }
    },
    addClick(item, i) {
      this.list[i].num++;
      this.numGet(item.id, item.num, i);
      this.pushList();
    },
    handleBlur(item, i) {
      if (isNaN(this.list[i].num)) {
        this.$message.error("请输入数字");
        this.list[i].num = 1;
        return;
      }
      if (this.list[i].num < 1) {
        this.$message.error("产品数量不能小1件");
        this.list[i].num = 1;
        return;
      }
      this.numGet(item.id, item.num, i);
      this.pushList();
    },
    numGet(id, num, i) {
      this.$post("/web/car_change", {
        id,
        num,
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    ggClick(i) {
      this.pd = 1;
      this.list[i].xz = true;
    },
    ggHideClick(i) {
      this.pd = 0;
      this.list[i].xz = false;
    },
    ganbi() {
      if (this.pd == 1) {
        for (let i = 0; i < this.list.length; i++) {
          const e = this.list[i];
          e.xz = false;
        }
        this.pd = 0;
      }
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .contentBox {
    width: 1440px;
    margin: auto;
    box-sizing: border-box;
    .shopHeadBox {
      display: flex;
      align-items: center;
      height: 75px;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px;
      .img {
        height: 45px;
        img {
          object-fit: cover;
        }
      }
      .inpBox {
        width: 300px;
        height: 40px;
        border: 1px solid #ebebeb;
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        input {
          width: 100%;
          height: 100%;
          padding-left: 15px;
        }
        .searchBtn {
          cursor: pointer;
          width: 60px;
          height: calc(100% - 4px);
          position: absolute;
          right: 2px;
          top: 2px;
          background-color: rgb(233, 7, 7);
          color: #fff;
          font-weight: 600;
          font-size: 14px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .contBox {
      display: flex;
      .leftCont {
        width: calc(100% - 400px);
        box-shadow: 0 0 4px 2px #ebebeb;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px 15px;
        box-sizing: border-box;
        .leftTitle {
          font-weight: 700;
          font-size: 16px;
          margin-bottom: 20px;
        }
        .funBox {
          display: flex;
          align-items: center;
          .box {
            display: flex;
            align-items: center;
            cursor: pointer;
            .kuang {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #c9c9c9;
              width: 16px;
              height: 16px;
              border-radius: 4px;
              margin-right: 5px;
              .iconfont {
                font-size: 12px;
              }
            }
            .on {
              background-color: #e40505;
              border: 1px solid #e40505;
              color: #fff;
            }
            .text {
              color: #333;
            }
          }
          .txt {
            border: 1px solid #c9c9c9;
            border-radius: 4px;
            padding: 3px 10px;
            font-size: 12px;
            margin-left: 20px;
            cursor: pointer;
          }
        }
        .contList {
          margin-top: 10px;
          .cont {
            border-radius: 5px;
            background-color: white;
            border: 1px solid whitesmoke;
            box-sizing: border-box;
            margin-bottom: 10px;
            position: relative;
            .contHead {
              height: 45px;
              display: flex;
              background-color: #ececec;
              align-items: center;
              padding-left: 20px;
              cursor: pointer;
              .kuang {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #c9c9c9;
                width: 16px;
                height: 16px;
                border-radius: 4px;
                margin-right: 10px;
                .iconfont {
                  font-size: 12px;
                }
              }
              .on {
                background-color: #e40505;
                border: 1px solid #e40505;
                color: #fff;
              }
              .time {
                font-weight: 700;
              }
              .txt {
                margin-left: 15px;
                color: #888;
              }
            }
            .fhBag {
              background-color: rgb(224, 239, 255);
            }
            .honBag {
              background-color: rgb(255, 224, 224);
            }
            .contBox {
              display: flex;
              align-items: center;
              .cpBox {
                padding: 20px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                .img {
                  cursor: pointer;
                  width: 80px;
                  border-radius: 5px;
                  height: 80px;
                  overflow: hidden;
                  position: relative;
                  .delBox {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.4);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-size: 16px;
                    z-index: 5;
                  }
                  img {
                    object-fit: cover;
                  }
                }
              }
              .text {
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding: 10px 0;
                position: relative;
                .ggBox {
                  height: 80px;
                  cursor: pointer;
                  width: calc(100% - 30px);
                  position: relative;
                  .box {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: #f81616;
                    color: #fff;
                    font-size: 12px;
                    padding: 2px 6px;
                    border-bottom-left-radius: 4px;
                    display: none;
                  }
                }
                // .ggBox:hover {
                //   // animation: ggBoxB 0.2s both;
                //   // padding: 5px;
                //   // border: 1px dashed #f81616;
                //   // border-radius: 5px;
                //   // .box {
                //   //   display: block;
                //   // }
                // }
                @keyframes ggBoxB {
                  0% {
                    padding: 0;
                    border: 0 dashed #f81616;
                    border-radius: 0;
                  }
                  100% {
                    padding: 5px;
                    border: 1px dashed #f81616;
                    border-radius: 5px;
                  }
                }
                .on {
                  padding: 5px;
                  border: 1px dashed #f81616;
                  border-radius: 5px;
                }
                .onShow {
                  display: block !important;
                }
                .ggCont {
                  position: absolute;
                  top: 60px;
                  left: 0;
                  box-shadow: 0 0 4px 2px #ebebeb;
                  border-radius: 5px;
                  width: 300px;
                  height: 200px;
                  background-color: #fff;
                  z-index: 99;
                  box-sizing: border-box;
                  .boxCont {
                    height: calc(100% - 60px);
                    overflow: auto;
                    padding: 0 15px;
                    margin: 10px 0;
                    margin-right: 5px;
                    .boxs {
                      display: flex;
                      .name {
                        color: #888;
                        width: 50px;
                        margin-top: 2px;
                        font-weight: 400;
                      }
                      .box {
                        width: calc(100% - 50px);
                        .txt {
                          cursor: pointer;
                          font-weight: 400;
                          float: left;
                          border: 1px solid #d6d6d6;
                          padding: 3px 8px;
                          font-size: 12px;
                          border-radius: 5px;
                          margin-right: 10px;
                          margin-bottom: 10px;
                        }
                      }
                    }
                  }
                  .ggBtns {
                    height: 40px;
                    border-top: 1px solid #ebebeb;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .ggBtn {
                      background-color: #e40505;
                      color: #fff;
                      height: 25px;
                      font-size: 12px;
                      border-radius: 4px;
                      width: 50px;
                      margin-right: 15px;
                      cursor: pointer;
                    }
                    .ggBtn1 {
                      height: 25px;
                      font-size: 12px;
                      border-radius: 4px;
                      border: 1px solid #ebebeb;
                      width: 50px;
                      box-sizing: border-box;
                      margin-right: 15px;
                      cursor: pointer;
                    }
                  }
                }
                .name {
                  font-weight: 600;
                }
                .txt {
                  font-weight: 600;
                  color: #888;
                }
                .butBox {
                  height: 30px;
                  width: 60px;
                  border: 1px solid #c9c9c9;
                  border-radius: 4px;
                  font-size: 12px;
                  cursor: pointer;
                  margin-bottom: 10px;
                  margin: auto;
                }
                .butBox:last-child {
                  margin-bottom: 0;
                }
              }
              .numBox {
                display: flex;
                align-items: center;
                justify-content: center;
                .box {
                  height: 30px;
                  width: 100px;
                  border-radius: 5px;
                  border: 1px solid #c9c9c9;
                  display: flex;
                  .jian {
                    width: 30px;
                    box-sizing: border-box;
                    border-right: 1px solid #c9c9c9;
                    cursor: pointer;
                  }
                  .num {
                    width: 40px;
                    overflow: hidden;
                    input {
                      width: 100%;
                      height: 100%;
                      text-align: center;
                    }
                  }
                  .jia {
                    width: 30px;
                    box-sizing: border-box;
                    border-left: 1px solid #c9c9c9;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
      .rightCont {
        width: 400px;
        padding-left: 15px;
        .cont {
          box-shadow: 0 0 4px 2px #ebebeb;
          background-color: #fff;
          border-radius: 10px;
          overflow: hidden;
          .contHead {
            padding-top: 20px;
            padding-left: 15px;
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ebebeb;
            .name {
              font-size: 16px;
              font-weight: 600;
              margin-right: 5px;
            }
            .txt {
              span {
                font-size: 18px;
                font-weight: 600;
                padding: 0 1px;
              }
            }
          }
          .box {
            height: 150px;
          }
          .cpBox {
            padding: 15px;
            .imgs {
              overflow: hidden;
              .img {
                position: relative;
                width: calc(25% - 11.25px);
                margin-right: 15px;
                float: left;
                padding-top: calc(25% - 11.25px);
                margin-bottom: 15px;
                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  object-fit: cover;
                }
                .text {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  height: 20px;
                  width: 100%;
                  background-color: rgba(0, 0, 0, 0.3);
                  color: #fff;
                  padding: 0 5px;
                  box-sizing: border-box;
                  font-size: 10px;
                  line-height: 20px;
                }
              }
              .img:nth-child(4n) {
                margin-right: 0;
              }
            }
            .contTxt {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .name {
                font-weight: 600;
                position: relative;
                top: 2px;
              }
              .txt {
                font-size: 18px;
                font-weight: 600;
                span {
                  font-size: 12px;
                  font-weight: 400;
                }
              }
            }
          }
          .contBtn {
            height: 70px;
            display: flex;
            align-items: center;
            border-top: 1px solid #ebebeb;
            justify-content: space-between;
            padding: 0 15px;
            .moneyBox {
              font-weight: 600;
              display: flex;
              align-items: flex-end;
              .name {
                position: relative;
                top: -5px;
                margin-right: 5px;
              }
              .num {
                font-size: 26px;
                color: #f81616;
              }
            }
            .saveBox {
              display: inline-block;
              height: 48px;
              width: 120px;
              background-color: #f81616;
              border-radius: 8px;
              font-family: PingFangSC;
              font-weight: 600;
              font-size: 16px;
              color: #fff;
              text-align: center;
              line-height: 48px;
              text-decoration: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
